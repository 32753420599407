import React from 'react';
import { Link } from 'react-router-dom';
import './verticalMenu.css';

const VerticalMenu = () => {
  return (
    <nav className="nav">
      <ul className="menu">
        <li className="menuItem"><Link className="link" to="about">About</Link></li>
        <li className="menuItem"><Link className="link" to="contact">Contact</Link></li>        
        <li className="menuItem"><Link className="link" to="none">Another</Link></li>        
        <li className="menuItem"><Link className="link" to="none">Menu item</Link></li>        
        <li className="menuItem"><Link className="link" to="none">Here as well</Link></li>        
      </ul>
    </nav>
  );
};

export default VerticalMenu;
