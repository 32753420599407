import React from 'react';
import PropTypes from 'prop-types';

function ProjectCard({ image, title }) {
    return (
        <div style={styles.container}>
            <img src={image} alt={title} style={styles.image} />
            <div style={styles.content}>
                <h1 style={styles.title}>{title}</h1>
                {/* You can add more content here */}
            </div>
        </div>
    );
}

const styles = {
    container: {
        backgroundColor: '#f4f4f4',
        width: '17em',
        height: '20em',
        borderRadius: '1em',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    image: {
        width: '100%',
        height: '10em',
        objectFit: 'cover',
    },
    content: {
        padding: '1em',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        margin: 0,
        fontSize: '1.5em',
    }
}

// Adding propTypes for validation
ProjectCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default ProjectCard;
