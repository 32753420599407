import React from 'react'

function Contact() {
    return (
        <div>
            <h1>This is the Contact page</h1>
        </div>
    )
}

export default Contact