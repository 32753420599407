import React from 'react'
import CardList from '../../components/cards/cardlist/CardList'

function Home() {
    return (
        <div style={styles.container}>
           <CardList />
        </div>
    )
}

const styles = {
    container: {
        margin: '1em 2em 2em 2em',
    }
}

export default Home