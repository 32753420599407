import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import VerticalMenu from './components/menu/verticalmenu/VerticalMenu';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Home from './pages/home/home';
import { useState } from 'react';
import ReactFacebookLogin from 'react-facebook-login';

function App() {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState('');

  const responseFacebook = (response) => {
    console.log(response);
    setData(response);
    setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }

  return (
    <div className="App">
      <Header />
      {!login &&
            <ReactFacebookLogin
              appId="837198558516199"
              autoLoad={false}
              fields="name,email,picture"
              scope="public_profile"
              callback={responseFacebook}
              icon="fa-facebook" />
          }
          {login &&
            <img src={picture} />
          }
      <div style={styles.bodycontainer}>
        <VerticalMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="none" element={<div>Not implemented yet...</div>} />
        </Routes>
      </div>
    </div>
  );
}

const styles = {
  bodycontainer: {
    display: 'flex'
  }
};

export default App;
