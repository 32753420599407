import React from 'react';
import ProjectCard from '../projectcard/projectcard';

const lists = [
    {
        image: '/images/restaurant.jpg',
        title: 'Join the restaurant project',
    },
    {
        image: '/images/golf.jpg',
        title: 'Golf trainer needed',
    },
    {
        image: '/images/knitting.jpg',
        title: 'Knitting partner plz',
    },
    {
        image: '/images/boardgames.jpg',
        title: 'Wanna build a board game??',
    },
];

function CardList() {
    return (
        <div style={styles.container}>
            {lists.map((card, index) => (
                <ProjectCard 
                    key={index} // Ideally, use a unique ID for the key
                    image={card.image} 
                    title={card.title} 
                />
            ))}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1em', // Space between cards
        margin: '1em 2em 2em 2em',
    },
};

export default CardList;
