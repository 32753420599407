import React from 'react';

const Header = () => {
  return (
    <header style={styles.header}>
      <h1 style={styles.title}>SkillOrbit</h1>
    </header>
  );
};

const styles = {
  header: {
    backgroundColor: '#3573e9',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    height: '5em',
    zIndex: 1000,
    alignContent: 'center',
    align: 'left',
  },
  title: {
    fontSize: '2rem',
    margin: 0,
    fontWeight: 'bold',
  },
};

export default Header;
 